import { Input, Chip } from '@telegram-apps/telegram-ui'
import { useState, useContext } from 'react'
import CommonNavBar from '../../Common/CommonNavBar'
import { OrderList } from '../index'
import styles from './styles.module.css'
import mainStyles from '../../main-styles.module.css'
import { ThemeContext } from '../../../lib'
import { LocalizedStrings } from '../../../res/localization'

const Orders = () => {
  const [search, setSearch] = useState('')
  const [selectedFilter, setSelectedFilter] = useState(0)
  const { isLightTheme } = useContext(ThemeContext)
  const [filterMonths, setFilterMonths] = useState([])

  // api.get('/api/orders/personal').then(response => {
  //   console.log('Ответ от сервера:', response.data)
  //   const comp = response.data.map((item) => {
  //     return item.compositionOrders
  //   })

  //   console.log('mapped', comp)
  // })

  // api.get('/api/cart').then(response => {
  //   console.log('Ответ от сервера:', response.data)
  // })

  const months = [
    { id: 0, name: LocalizedStrings.ordersAll },
    { id: 1, name: LocalizedStrings.ordersJanuary },
    { id: 2, name: LocalizedStrings.ordersFebruary },
    { id: 3, name: LocalizedStrings.ordersMarch },
    { id: 4, name: LocalizedStrings.ordersApril },
    { id: 5, name: LocalizedStrings.ordersMay },
    { id: 6, name: LocalizedStrings.ordersJune },
    { id: 7, name: LocalizedStrings.ordersJuly },
    { id: 8, name: LocalizedStrings.ordersAugust },
    { id: 9, name: LocalizedStrings.ordersSeptember },
    { id: 10, name: LocalizedStrings.ordersOctober },
    { id: 11, name: LocalizedStrings.ordersNovember },
    { id: 12, name: LocalizedStrings.ordersDecember }
  ]

  return (
    <div className={isLightTheme ? styles.OrdersMainWrapperLight : styles.OrdersMainWrapperDark}>
      <div className={styles.OrdersSearchBarWrapper}>
        <h2 className={styles.OrdersSearchBarHeader}>{LocalizedStrings.searchBarHeader}</h2>
        <div className={isLightTheme ? styles.SearchBarInputNew : styles.SearchBarInputNewDark}>
          <input
            placeholder={LocalizedStrings.orderSearchBarPlaceholder}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          ></input>
        </div>
      </div>

      <div className={styles.OrdersMonthChipsWrapper}>
        {months.map((month) => (
          (filterMonths.includes(month.id) || month.id === 0) &&
          <Chip
            className={`${isLightTheme ? mainStyles.FilterChip : mainStyles.FilterChipDark} ${selectedFilter === month.id ? mainStyles.selected : mainStyles.unselected}`}
            mode={''}
            key={month.id}
            onClick={() => setSelectedFilter(month.id)}
          >
            <div className={isLightTheme ? mainStyles.FilterChipText : mainStyles.FilterChipTextDark}>{month.name}</div>
          </Chip>
        ))}
      </div>

      <OrderList filter={selectedFilter} search={search} monthsWithOrders={setFilterMonths} isLightTheme={isLightTheme} />

    </div>
  )
}

export default Orders
