import { Button, Text } from '@telegram-apps/telegram-ui'
import { useLocation, useNavigate } from 'react-router-dom'
import { retrieveLaunchParams } from '@telegram-apps/sdk'
import { useEffect, useState } from 'react'

const TransactionHandler = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [transactionData, setTransactionData] = useState({})

  const retrieveTransactionData = () => {
    const transactionResponse = location.search.slice(1)
    const encodedParam = btoa(transactionResponse) // Кодируем строку в base64
    return queryStringToObject(atob(encodedParam))
  }

  const receiveTransactionData = () => {
    const transactionResponse = location.search.slice(1)
    const encodedParam = btoa(transactionResponse) // Кодируем строку в base64
    const parsedTransactionData = retrieveTransactionData()
    if (!parsedTransactionData.OutSum || !parsedTransactionData.InvId || !parsedTransactionData.SignatureValue) {
      navigate('/')
      return
    }
    setTransactionData(parsedTransactionData)
    // console.log('encode data: ', queryStringToObject(atob(encodedParam)))
    return encodedParam
  }

  function queryStringToObject (query) {
    const result = {}

    // Разделяем строку по символу "&", чтобы получить пары "ключ=значение"
    const pairs = query.split('&')

    // Для каждой пары, разделяем на ключ и значение, и добавляем их в объект
    pairs.forEach(pair => {
      const [key, value] = pair.split('=')
      result[key] = decodeURIComponent(value) // Используем decodeURIComponent для декодирования значений
    })
    return result
  }

  const returnToMiniApp = () => {
    const transactionResponse = location.search.slice(1)
    if (!transactionResponse) {
      return
    }
    window.open(`https://t.me/TeaStore2323_bot/teahouse2323store?startapp=${btoa(`path=orderplaced&orderId=${retrieveTransactionData().InvId}`)}`, '_blank')
  }

  useEffect(() => {
    try {
      receiveTransactionData()
      if (retrieveLaunchParams != null) {
        returnToMiniApp()
      } else {
        navigate('/settings')
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', gap: '4vh' }}>
    <Text>{`Проверка оплаты заказа #${transactionData?.InvId} в процессе, для продолжения перейдите по ссылке`}</Text>
    <Button mode='filled' onClick={() => returnToMiniApp()}>Вернуться в магазин</Button>
    </div>
  )
}

export default TransactionHandler
