import { useNavigate } from 'react-router-dom'
import { terms } from './TermsFile'

const TermsOfUsage = () => {
  const navigate = useNavigate()

  const BackButton = window.Telegram.WebApp.BackButton
  BackButton.show()
  BackButton.onClick(function () {
    BackButton.hide()
  })
  window.Telegram.WebApp.onEvent('backButtonClicked', function () {
    navigate('/settings')
  })

  return <pre style={{
    fontFamily: 'monospace, system-ui,-apple-system,BlinkMacSystemFont,"Roboto","Apple Color Emoji","Helvetica Neue",sans-serif',
    fontSize: '15px',
    margin: '16px 12px 0 16px',
    paddingBottom: '16px',
    whiteSpace: 'pre-line'
  }}>{ terms }</pre>
}

export default TermsOfUsage
