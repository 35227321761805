import React from 'react'
import { FormInputError } from './styled'
import { Input } from '../Input'
import styles from './styles.module.css'

export const FormInput = React.forwardRef(({ error, onKeyDown, isLightTheme, isLast, enterKeyHint, autoComplete, ...props }, ref) => {
  return (
      <div className={isLightTheme ? `${styles.FormInputWrapper} ${isLast ? styles.last : ''}` : styles.FormInputWrapperDark}>
      <Input {...props} ref={ref} onKeyDown={onKeyDown} enterKeyHint={enterKeyHint} autoComplete={autoComplete} />
      {error && <FormInputError>{error}</FormInputError>}
      </div>
  )
})

FormInput.displayName = 'FormInput'
