import { List, Section, Cell, Switch, Button, Subheadline, FixedLayout, Spinner } from '@telegram-apps/telegram-ui'
import { useState, useEffect, useContext } from 'react'
import { orderBox, orderTea } from '../../../../../res/icons/index.js'
import { CatalogPaymentMethodPopUp, HapticTouchBottomButton } from '../../../index.js'
import { get, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { orderSchema } from '../../../../../model/index.js'
import { FormInput } from '../../../../Common/index.js'
import customStorage from '../../../../../CustomStorage.js'
import { TonConnectButton, useTonWallet, useTonAddress, useTonConnectUI, THEME } from '@tonconnect/ui-react'
import { Cell as TonCell } from '@ton/core'
import { beginCell } from '@ton/ton'
import { getEnv, getImageUrl, getCurrencyRegularString, getPriceByCurrency, tonToNanoton, calculateOrderSum, calculateOrderSumByCurrency, ThemeContext, calculateOrderSumByConcreteCurrency, Currencies, getPriceByConcreteCurrency, getOrderDetailsProductInfo, getOrderDetailsItemPrice, standardMeasureCalcPieceProp } from '../../../../../lib'
import styles from './styles.module.css'
import mainStyles from '../../../../main-styles.module.css'
import { useNavigate } from 'react-router-dom'
import { useCartStore, useUserStore } from '../../../../../store'
import { cartApi, getGoodsAvailability as getGoodsAvailabilityApi, generateRobokassaLink, getRobokassaPendingTransactions, getSpecificOrderInfo } from '../../../../../api'
import ImageLoader from 'react-imageloader'
import { LocalizedStrings } from '../../../../../res/localization/index.js'
import { ConnectedWalletPopUp } from './Objects/ConnectedWalletPopUp.js'
import { formatPhoneNumberInput, stylishPhoneNumber } from '../../../../../lib/PhoneExtensions.js'
import { openLink } from '@telegram-apps/sdk'

export const CatalogOrderAddingYourOrderDeliveryDetails = () => {
  const [saveInfo, setSaveInfo] = useState(false)
  const [CartInfo, setCartInfo] = useState([])
  const [selectedMethod, setSelectedMethod] = useState(localStorage.getItem('selectedPaymentMethod') || 'mir')
  const [isActiveMir, setIsActiveMir] = useState(false) // состояние для управления видимостью попапа
  const [isActiveTon, setIsActiveTon] = useState(true)
  const [isActiveTelegram, setIsActiveTelegram] = useState(false)

  const { deliveryInfo, receiver } = useUserStore(store => store.user)
  const updateDeliveryDetails = useUserStore(store => store.update)

  const paymentMethods = [
    { id: 'mir', label: LocalizedStrings.mirPaymentMethod, description: LocalizedStrings.mirPaymentMethodDescription, active: setIsActiveMir, isActive: isActiveMir },
    { id: 'ton', label: LocalizedStrings.tonPaymentMethod, description: LocalizedStrings.tonPaymentMethodDescription, active: setIsActiveTon, isActive: isActiveTon }
    // { id: 'telegram', label: 'Telegram Stars', description: 'Pay with Telegram Stars', active: setIsActiveTelegram, isActive: isActiveTelegram }
  ]
  const [currentActiveId, setCurrentActiveId] = useState(localStorage.getItem('selectedPaymentMethod') || 'mir')
  const { isLightTheme } = useContext(ThemeContext)
  const navigate = useNavigate()

  const [checkGoodsAvailabilityString, setCheckGoodsAvailabilityString] = useState(null)
  const [checkGoodsAvailabilityResult, setCheckGoodsAvailabilityResult] = useState(null)
  const order = useCartStore((state) => state.order)
  const currentOrderId = useCartStore.getState().orderId
  const [selectedOrderIdState, setSelectedOrderIdState] = useState(null)
  let selectedOrderId = null

  const [showCreateNewOrder, setShowCreateNewOrder] = useState(null)
  const [showInfoScreen, setShowInfoScreen] = useState(null)
  const [showOrderDetailsScreen, setShowOrderDetailsScreen] = useState(false)
  const [pendingTransactions, setPendingTransactions] = useState([])

  const [transactionPaymentStarted, setTransactionPaymentStarted] = useState(false)
  const [transactionLink, setTransactionLink] = useState(null)

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: zodResolver(orderSchema),
    defaultValues: {
      address: '',
      country: '',
      city: '',
      postcode: '',
      // state: '',
      name: '',
      phone: '+7',
      email: ''
    },
    shouldFocusError: false
  })
  const { setValue } = form

  useEffect(() => {
    if (deliveryInfo) {
      setValue('address', deliveryInfo.address || '')
      setValue('country', deliveryInfo.country || '')
      setValue('city', deliveryInfo.city || '')
      setValue('postcode', deliveryInfo.postcode || '')
      // setValue('state', deliveryInfo.state || '')
    }
    if (receiver) {
      setValue('name', receiver.name || '')
      setValue('phone', stylishPhoneNumber(receiver.phone) || '+7')
      setValue('email', receiver.email || '')
    }
    setOptions({
      restoreConnection: true,
      language: customStorage.getItem('language'),
      uiPreferences: {
        theme: isLightTheme ? THEME.LIGHT : THEME.DARK,
        borderRadius: 's'
      }
    })
  }, [deliveryInfo, receiver])

  useEffect(() => {
    if (currentActiveId === 'ton') {
      setPaymentTotalSum(calculateOrderSumByConcreteCurrency(order, Currencies.TON))
      localStorage.setItem('selectedPaymentMethod', 'ton')
    }
    if (currentActiveId === 'mir') {
      setPaymentTotalSum(calculateOrderSumByConcreteCurrency(order, Currencies.RUB))
      localStorage.setItem('selectedPaymentMethod', 'mir')
    }
  }, [currentActiveId, order])

  const tonWallet = useTonWallet()
  const TonAddress = useTonAddress()
  // console.log("current Ton wallet", tonWallet, "\ncurrent Ton address: ", TonAddress);
  const [tonConnectUI, setOptions] = useTonConnectUI()
  const [TotalSum, setTotalSum] = useState(0)
  const [PaymentTotalSum, setPaymentTotalSum] = useState(0)

  // eslint-disable-next-line prefer-const
  let paymentButtonDisabled = (TonAddress.length === 0 && selectedMethod === 'ton') || checkGoodsAvailabilityResult === false

  function getTransaction () {
    const info = {
      orderId: currentOrderId,
      orderSum: parseFloat(PaymentTotalSum)
    }
    const orderInfo = JSON.stringify(info)

    const body = beginCell()
      .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
      .storeStringTail(orderInfo) // write our text comment
      .endCell()

    const transaction = {
      validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
      messages: [
        {
          address: getEnv().REACT_APP_TRANSACTION_WALLET, // wallet
          amount: tonToNanoton(PaymentTotalSum), // Toncoin in nanotons, 0,001 TON here, TODO: add converter from dollar sum to nanotons later; 1 milliard nanoTons (1000000000) = 1 TON
          payload: body.toBoc().toString('base64')
        }
      ]
    }
    console.log('Builded transaction form: ', transaction, orderInfo)
    return transaction
  }

  const checkCartGoodsAvailability = async () => {
    setCheckGoodsAvailabilityString('Started check availability of selected products, please wait...')
    try {
      const response = await getGoodsAvailabilityApi()
      setCheckGoodsAvailabilityString(response.data.message)
      setCheckGoodsAvailabilityResult(response.data.allGoodsAvailable)
      if (response.data.allGoodsAvailable) {
        if (selectedMethod === 'ton') {
          startTonPayment()
        }
        if (selectedMethod === 'mir') {
          startMirPayment()
        }
      }
    } catch (error) {
      setCheckGoodsAvailabilityString('Error occured while check availability of selected products, try open this page again.')
      setCheckGoodsAvailabilityResult(false)
      console.error('Произошла ошибка:', error)
    }
  }

  const finishCart = async (robokassaPayment, robokassaTransactionLink) => {
    const sum = calculateOrderSum(order)
    const body = {
      orderId: currentOrderId,
      orderSum: parseFloat(sum.toFixed(2)),
      paymentMethod: (robokassaPayment ? 'robokassa' : null),
      robokassaTransactionLink: (robokassaPayment ? robokassaTransactionLink : null)
    }
    console.log('finish cart with body: ', body)

    try {
      await cartApi.finishCart(body)
    } catch (error) {
      console.error('Произошла ошибка при очистке корзины клиента:', error)
    }
  }

  useEffect(() => {
    if (order) {
      setTotalSum(calculateOrderSumByCurrency(order))
      checkCreateNewOrderAllowed()
      console.log('order', currentOrderId)
    }
    if (currentOrderId && order.length === 0) {
      navigate('/')
    }
  }, [order])

  useEffect(() => {
    if (currentOrderId) {
      setSelectedOrderIdState(currentOrderId)
      selectedOrderId = currentOrderId
    }
    console.log('currentOrderId from store', currentOrderId)
  }, [currentOrderId])

  useEffect(() => {
    console.log('selectedOrderId', selectedOrderId)
  }, [selectedOrderId])

  const checkCreateNewOrderAllowed = async () => {
    const response = await getRobokassaPendingTransactions()
    setPendingTransactions(response.data.paymentLinks)
    if (response.data.paymentLinks.length === 0) {
      setShowInfoScreen(false)
      setShowCreateNewOrder(false)
      setShowOrderDetailsScreen(true)
    }
    if (response.data.newPaymentAllowed === false) {
      setShowInfoScreen(true)
      setShowCreateNewOrder(false)
    }
    if (response.data.newPaymentAllowed === true && response.data.paymentLinks.length > 0) {
      setShowInfoScreen(true)
      setShowCreateNewOrder(true)
    }
  }

  const BackButton = window.Telegram.WebApp.BackButton
  BackButton.show()
  BackButton.onClick(function () {
    BackButton.hide()
  })
  window.Telegram.WebApp.onEvent('backButtonClicked', function () {
    //  hideDeliveryDetailsPage()
    navigate('/vieworder')
  })

  const onSubmit = async (data) => {
    checkCartGoodsAvailability()
    const formattedData = {
      ...data,
      phone: data.phone.replace('+7', '8').replace(/\D/g, '')
    }

    cartApi.updateOrderDeliveryInfo(formattedData, currentOrderId)
    if (saveInfo) {
      try {
        updateDeliveryDetails(formattedData)
      } catch (error) {
        console.error('Произошла ошибка при обновлении данных:', error)
      }
    }
  }

  const goToOrderPlacedPage = (orderId, orderSum, purchaseType) => {
    if (purchaseType !== 'robokassa') {
      finishCart()
    }
    navigate('/orderplaced', { state: { orderId, orderSum, purchaseType } })
  }

  const startMirPayment = async () => {
    try {
      const orderId = currentOrderId
      const sum = calculateOrderSumByConcreteCurrency(order, Currencies.RUB)
      setPaymentTotalSum(sum)
      const outSum = parseFloat(sum.toFixed(2))
      const receipt = []
      order.forEach((item) => {
        receipt.push({
          name: item.good.caption,
          // sum: parseFloat((item.count * getPriceByConcreteCurrency(item.good.price, item.packageWeight.replace(/\D/g, ''), Currencies.RUB)).toFixed(2)),
          sum: parseFloat(
            (item.count *
              getPriceByCurrency(
                item.good.price, (
                  item.good.addonProps.measureCalcValue === 'weight' ? item.packageWeight.replace(/\D/g, '') : standardMeasureCalcPieceProp
                )
              )
            ).toFixed(2)
          ),
          quantity: item.count,
          tax: 'none'
        })
      })
      const encodedReceipt = encodeURIComponent(JSON.stringify(receipt))
      const link = await generateRobokassaLink({ outSum, orderId, receipt: encodedReceipt })
      selectedOrderId = orderId
      setTransactionLink(link.data.generatedLink)
      openTransactionPaymentScreen(link.data.generatedLink)
      finishCart(true, link.data.generatedLink)
      setTransactionPaymentStarted(true)
      checkRobokassaOrderStatus()
    } catch (error) {
      console.error('Произошла ошибка при оплате через MIR:', error)
    }
  }

  const openTransactionPaymentScreen = (link) => {
    // Проверяем, поддерживает ли Telegram Web App API
    if (window.Telegram) {
      window.Telegram.WebApp.openLink(link)
    } else {
      // Если в Telegram API не доступно, просто открываем ссылку в браузере
      window.open(link, '_blank')
    }
  }

  const checkRobokassaOrderStatus = async () => {
    try {
      console.log('checkRobokassaOrderStatus of order id = ', selectedOrderId)
      const orderInfo = await getSpecificOrderInfo(selectedOrderId) // Выполняем запрос

      if (orderInfo.data.status === 'paid') {
        goToOrderPlacedPage(selectedOrderIdState, PaymentTotalSum, 'robokassa')
        return // Прекращаем выполнение
      }

      // Если статус не "Paid", повторяем через 10 секунд
      setTimeout(checkRobokassaOrderStatus, 10000)
    } catch (error) {
      console.error('Ошибка при получении статуса ордера:', error)
      // В случае ошибки можно попробовать снова через 10 секунд
      setTimeout(checkRobokassaOrderStatus, 10000)
    }
  }

  const startTonPayment = () => {
    const sum = calculateOrderSum(order)
    const orderId = currentOrderId
    const orderSum = parseFloat(sum.toFixed(2))
    if (TonAddress.length > 0) {
      console.log('startPayment')
      try {
        tonConnectUI.sendTransaction(getTransaction()).then(async (result) => {
          const cell = TonCell.fromBase64(result.boc)
          const buffer = cell.hash()
          const hashHex = buffer.toString('hex')
          console.log('Transaction hash: ', hashHex)
          console.log(`Path to see transaction info: ${getEnv().REACT_APP_TON_CONNECT_TRANSACTION_INFO_URL}` + hashHex + '')
          goToOrderPlacedPage(orderId, orderSum, 'ton')
        })
      } catch (error) {
        console.log('Transaction error: ', error)
      }
    }
  }

  function preloader () {
    return <img height={36} width={36} src={orderTea}></img>
  }

  const handleDisconnect = () => {
    tonConnectUI.disconnect()
  }

  const handleChangeInputFocus = (e, nextIndex) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      document.querySelectorAll('input')[nextIndex].focus()
    }
  }

  const handlePhoneChange = (event) => {
    const rawValue = event.target.value
    const cursorPosition = event.target.selectionStart

    // Проверяем, пытается ли пользователь удалить префикс "+7"
    if (cursorPosition <= 3 && rawValue.startsWith('+7') === false) {
      if (rawValue.length < 3) {
        event.target.value = '+7 '
      } else {
        event.target.value = '+7' + rawValue.replace('+', '') // Восстанавливаем префикс
      }
      return
    }

    const formattedValue = formatPhoneNumberInput(rawValue)
    setValue('phone', formattedValue, { shouldValidate: true })

    // Ставим курсор в правильное место
    requestAnimationFrame(() => {
      const input = event.target
      if (cursorPosition === rawValue.length) {
        input.setSelectionRange(cursorPosition + 3, cursorPosition + 3)
      } else {
        input.setSelectionRange(cursorPosition, cursorPosition)
      }
    })
  }

  const goToCreateNewOrder = () => {
    setShowInfoScreen(false)
    setShowOrderDetailsScreen(true)
  }

  const goToPayPreviousPendingOrder = (index) => {
    console.log('goToPayPreviousPendingOrder of id ', pendingTransactions[index].orderId)
    selectedOrderId = pendingTransactions[index].orderId
    setSelectedOrderIdState(pendingTransactions[index].orderId)
    setTransactionLink(pendingTransactions[index].link)
    setTransactionPaymentStarted(true)
    setTimeout(checkRobokassaOrderStatus, 5000)
    openTransactionPaymentScreen(pendingTransactions[index].link)
  }

  if (transactionPaymentStarted === true) {
    return (
      <div
      className={isLightTheme ? styles.MainContentWrapperLight : styles.MainContentWrapperDark}
      style={{ height: '100%' }}
      >
      <div
      className={mainStyles.SpinnerLoadingContent}
      >
      <Spinner />
      <div className={styles.InfoScreenTitle}>
        {`Запущен процесс оплаты заказа №${selectedOrderIdState}.
         Нажмите на кнопку ниже, если не открылась форма оплаты заказа`}
        </div>
      <Button
        onClick={() => openTransactionPaymentScreen(transactionLink)}
              className={styles.InfoScreenPreviousOrdersButton}
              >
                {'Перейти к оплате'}
              </Button>
      </div>
      </div>
    )
  } else {
    if (showInfoScreen === null) {
      return (
        <div
        className={mainStyles.SpinnerLoadingContent}
        >
        <Spinner />
        </div>
      )
    }

    if (showInfoScreen === true) {
      return (
        <div className={isLightTheme ? styles.MainContentWrapperLight : styles.MainContentWrapperDark} style={{ height: '100%' }}>
          <div className={styles.InfoScreenContent} style={{ marginTop: '20vh' }} >
            <div className={styles.InfoScreenTitle}>У вас есть заказы в обработке. Убедитесь, что вы точно их оплатили</div>
            {
              pendingTransactions.map((item, index) => (
                <Button
                key={index}
                onClick={() => goToPayPreviousPendingOrder(index)}
                className={styles.InfoScreenPreviousOrdersButton}
                >
                  {`Перейти к оплате заказа №${item.orderId}`}
                </Button>
              ))
            }
            {
              showCreateNewOrder === true &&
              <Button
              className={styles.InfoScreenResumeCreateNewOrder}
              onClick={goToCreateNewOrder}
              >
                Оформить новый заказ
              </Button>
            }
          </div>
        </div>
      )
    }

    if (showOrderDetailsScreen === true) {
      return (
      <form
        className={isLightTheme ? styles.MainContentWrapperLight : styles.MainContentWrapperDark}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <List className={styles.MainContent}>

          {order &&
            <Section header={<h2 className={styles.SectionHeader}>{LocalizedStrings.orderSectionHeader}</h2>} className={styles.OrderSectionWrapper}>
              <Cell
                className={isLightTheme ? styles.OrderInfoCell : styles.OrderInfoCellDark}
                before={<div alt="box" className={styles.OrderInfoCellIcon}>📦</div>}
              >
                <span className={styles.OrderInfoCellTitle}>
                  {LocalizedStrings.orderNumber + currentOrderId}
                </span>
              </Cell>

              {
                order.map((item, index) => (
                  <>
                  <div key={index} className={isLightTheme ? styles.container : styles.containerDark}>
                    <div className={styles.gridContainer}>
                      <div className={styles.flexContainer}>
                        <ImageLoader
                          src={getImageUrl(item.good.personalImage ? item.good.personalImage : item.good.article.image)}
                          className={styles.productImage}
                          alt="Product picture"
                          preloader={preloader}
                        >
                          <img src={orderTea}></img>
                        </ImageLoader>

                        <div className={styles.textContainer}>
                          <div className={styles.caption}>{item.good.caption}</div>
                          <div className={styles.additionalInfo}>
                            {getOrderDetailsProductInfo(item)}
                          </div>
                        </div>
                      </div>
                      <div className={styles.price}>
                        {getOrderDetailsItemPrice(item)}
                      </div>
                    </div>
                  </div>
                  {<div className={mainStyles.SectionDivider} />}
                  </>
                ))
              }

              {checkGoodsAvailabilityString &&
                <Cell
                  style={{ borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)', backgroundColor: isLightTheme ? 'white' : '#2E2E2E' }}
                  before={<div style={{ margin: '12px 0', color: (checkGoodsAvailabilityResult === true ? '#007AFF' : 'red') }}>{checkGoodsAvailabilityString}</div>}
                />
              }
              <Cell
                before={LocalizedStrings.totalPrice}
                after={<span className={styles.OrderSectionTotalPrice}>{getCurrencyRegularString(TotalSum.toFixed(2))}</span>} // replace counting to product.good.price later
                className={isLightTheme ? styles.OrderSectionTotalWrapper : styles.OrderSectionTotalWrapperDark}
              />
            </Section>
          }

          <Section header={<h2 className={styles.SectionHeader}>{LocalizedStrings.deliverySectionHeader}</h2>} >
            <CatalogPaymentMethodPopUp
              selectedMethod={selectedMethod}
              setSelectedMethod={setSelectedMethod}
              paymentMethods={paymentMethods}
              currentActiveId={currentActiveId}
              setCurrentActiveId={setCurrentActiveId}
            />
            {(TonAddress && selectedMethod === 'ton') &&
              <>
                {isLightTheme && <div className={mainStyles.SectionDivider}></div>}
                <ConnectedWalletPopUp
                  walletAddress={TonAddress}
                  onDisconnect={handleDisconnect}
                />
              </>
            }
          </Section>

          {selectedMethod === 'ton' &&
            <div
              className={styles.TonConnectButtonWrapper}
              onClickCapture={(ev) => {
                ev.preventDefault()
              }}
            >
              {!TonAddress &&
                <TonConnectButton />
              }
            </div>
          }

          <Section
            header={<h2 className={styles.SectionHeader}>{LocalizedStrings.deliveryInformationSectionHeader}</h2>}
            className={styles.DeliveryInformationSectionWrapper}
          >
            <FormInput
              value={form.getValues('country')}
              {...form.register('country')}
              placeholder={LocalizedStrings.countryInputPlaceholder}
              error={form.formState.errors.country?.message || ''}
              onKeyDown={(ev) => { handleChangeInputFocus(ev, 1) }}
              enterKeyHint='done'
              isLightTheme={isLightTheme}
            />
            <FormInput
              value={form.getValues('city')}
              {...form.register('city')}
              placeholder={LocalizedStrings.cityInputPlaceholder}
              error={form.formState.errors.city?.message || ''}
              onKeyDown={(ev) => { handleChangeInputFocus(ev, 2) }}
              enterKeyHint='done'
              isLightTheme={isLightTheme}
            />
            <FormInput
              value={form.getValues('address')}
              {...form.register('address')}
              placeholder={LocalizedStrings.addressInputPlaceholder}
              error={form.formState.errors.address?.message || ''}
              onKeyDown={(ev) => { handleChangeInputFocus(ev, 3) }}
              enterKeyHint='done'
              isLightTheme={isLightTheme}
            />
            <FormInput
              value={form.getValues('postcode')}
              {...form.register('postcode')}
              type="number"
              inputMode="numeric"
              placeholder={LocalizedStrings.postcodeInputPlaceholder}
              error={form.formState.errors.postcode?.message || ''}
              onKeyDown={(ev) => { handleChangeInputFocus(ev, 4) }}
              enterKeyHint='done'
              isLightTheme={isLightTheme}
              isLast={true}
            />
          </Section>

          <Section
            header={<h2 className={styles.SectionHeader}>{LocalizedStrings.receiverSectionHeader}</h2>}
            footer={<div className={styles.SectionFooter}>{LocalizedStrings.receiverSectionFooter}</div>}
            className={styles.ReceiverInformationSectionWrapper}
          >
            <FormInput
              value={form.getValues('name')}
              {...form.register('name')}
              placeholder={LocalizedStrings.nameInputPlaceholder}
              error={form.formState.errors.name?.message || ''}
              onKeyDown={(ev) => { handleChangeInputFocus(ev, 5) }}
              enterKeyHint='done'
              isLightTheme={isLightTheme}
            />
            <FormInput
              value={form.getValues('phone')}
              {...form.register('phone', {
                required: 'Phone number is required',
                validate: (value) =>
                  value.length === 18 || 'Phone number must be fully entered'
              })}
              type="tel"
              inputmode="tel"
              autocomplete="tel"
              onChange={(ev) => { handlePhoneChange(ev) }} // Подключаем обработчик форматирования
              placeholder={LocalizedStrings.phoneNumberInputPlaceholder}
              error={form.formState.errors.phone?.message || ''}
              onKeyDown={(ev) => { handleChangeInputFocus(ev, 6) }}
              enterKeyHint='done'
              isLightTheme={isLightTheme}
            />
            <FormInput
              value={form.getValues('email')}
              {...form.register('email')}
              type="email"
              inputmode="email"
              autocomplete="email"
              placeholder={LocalizedStrings.emailInputPlaceholder}
              error={form.formState.errors.email?.message || ''}
              enterKeyHint='done'
              isLightTheme={isLightTheme}
            />
            <Cell
              before={<div className={styles.SwitchSaveInfo}>{LocalizedStrings.saveInfoSwitchLabel}</div>}
              after={<Switch value={saveInfo} onChange={() => setSaveInfo(!saveInfo)} />}
              className={isLightTheme ? styles.CellSaveInfo : styles.CellSaveInfoDark}
            />

          </Section>

        </List>

        <FixedLayout>
          <div className={isLightTheme ? styles.PaymentButtonWrapperLight : styles.PaymentButtonWrapperDark}>
            <HapticTouchBottomButton
              onClick={() => { }}
              disabled={paymentButtonDisabled}
              normalStyle={paymentButtonDisabled ? styles.PaymentButtonDisabled : styles.PaymentButton}
              pressedStyle={paymentButtonDisabled ? styles.PaymentButtonDisabled : styles.PaymentButtonPressed}
              buttonText={LocalizedStrings.payOrderButton + getCurrencyRegularString(TotalSum.toFixed(2))}
            />
          </div>
        </FixedLayout>

      </form>
      )
    }
  }
}
