import { Section, List, Snackbar } from '@telegram-apps/telegram-ui'
import { LocalizedErrorStrings, LocalizedStrings } from '../../../res/localization'
import React, { useState, useEffect, useContext } from 'react'
import customStorage from '../../../CustomStorage'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { receiverEditSchema, deliveryInfoEditSchema, registerField } from '../../../model'
import { FormInput } from '../../Common'
import { useUserStore } from '../../../store'
import styles from './styles.module.css'
import { ThemeContext } from '../../../lib'
import { useNavigate } from 'react-router-dom'
import { formatPhoneNumberInput, stylishPhoneNumber } from '../../../lib/PhoneExtensions'

export const SettingsDeliveryInfo = () => {
  // const { hideDeliveryInfoPage } = props
  // TODO: - implement content
  const { isLightTheme } = useContext(ThemeContext)
  const { deliveryInfo, receiver } = useUserStore(store => store.user)
  const updateDeliveryInfo = useUserStore(store => store.update)
  const [apiError, setApiError] = useState(null)
  const navigate = useNavigate()

  const deliveryInfoForm = useForm({

    defaultValues: {
      address: customStorage.getItem('clientaddress') !== 'Empty address' ? customStorage.getItem('clientaddress') : '',
      country: customStorage.getItem('clientcountry') !== 'Empty country' ? customStorage.getItem('clientcountry') : '',
      city: customStorage.getItem('clientcity') !== 'Empty city' ? customStorage.getItem('clientcity') : '',
      postcode: customStorage.getItem('clientpostcode') !== 'Empty postcode' ? customStorage.getItem('clientpostcode') : '',
      name: customStorage.getItem('clientname') !== 'Empty name' ? customStorage.getItem('clientname') : '',
      phone: customStorage.getItem('clientphone') !== 'Empty phone' ? customStorage.getItem('clientphone') : '+7',
      email: customStorage.getItem('clientemail') !== 'Empty email' ? customStorage.getItem('clientemail') : ''
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(deliveryInfoEditSchema.merge(receiverEditSchema)),
    shouldFocusError: false
  })
  const { setValue } = deliveryInfoForm

  useEffect(() => {
    if (deliveryInfo) {
      console.log('deliveryInfo', deliveryInfo, receiver)
      setValue('address', deliveryInfo.address || '')
      setValue('country', deliveryInfo.country || '')
      setValue('city', deliveryInfo.city || '')
      setValue('postcode', deliveryInfo.postcode || '')
      setValue('state', deliveryInfo.state || '')
    }
    if (receiver) {
      setValue('name', receiver.name || '')
      setValue('phone', stylishPhoneNumber(receiver.phone) || '+7')
      setValue('email', receiver.email || '')
    }
  }, [deliveryInfo, receiver])

  const BackButton = window.Telegram.WebApp.BackButton
  BackButton.show()
  BackButton.onClick(function () {
    BackButton.hide()
  })
  window.Telegram.WebApp.onEvent('backButtonClicked', function () {
    navigate('/settings')
    // hideDeliveryInfoPage()
  })

  const onDeliveryInfoSubmit = (data, ev) => {
    console.log('onDeliveryInfoSubmit', data)
    const formattedData = {
      ...data,
      phone: data.phone.replace('+7', '8').replace(/\D/g, '')
    }
    updateDeliveryInfo(formattedData, setApiError)
  }

  const handlePhoneChange = (event) => {
    const rawValue = event.target.value
    const cursorPosition = event.target.selectionStart

    // Проверяем, пытается ли пользователь удалить префикс "+7"
    if (cursorPosition <= 3 && rawValue.startsWith('+7') === false) {
      if (rawValue.length < 3) {
        event.target.value = '+7 '
      } else {
        event.target.value = '+7' + rawValue.replace('+', '') // Восстанавливаем префикс
      }
      return
    }

    const formattedValue = formatPhoneNumberInput(rawValue)
    setValue('phone', formattedValue, { shouldValidate: true })

    // Ставим курсор в правильное место
    requestAnimationFrame(() => {
      const input = event.target
      if (cursorPosition === rawValue.length) {
        input.setSelectionRange(cursorPosition + 3, cursorPosition + 3)
      } else {
        input.setSelectionRange(cursorPosition, cursorPosition)
      }
    })
  }

  const handleDeliveryInfoSubmit = deliveryInfoForm.handleSubmit(onDeliveryInfoSubmit)

  return (
    <div className={isLightTheme ? styles.MainContentWrapperLight : styles.MainContentWrapperDark}>
      {apiError && <Snackbar
        description={apiError.message}
        style={{ zIndex: 1000 }}
        duration={3000}
        onClose={() => setApiError(null)}
      >
        {LocalizedErrorStrings.apiErrorTitle}
      </Snackbar>}
      <List className={styles.MainContentContainer}>
        <form onSubmit={handleDeliveryInfoSubmit}>
        <Section header = {<h2 className={styles.SectionHeader}>{LocalizedStrings.deliveryInformationSectionHeader}</h2>}>
            <FormInput
              placeholder={LocalizedStrings.addressInputPlaceholder}
              value={deliveryInfoForm.getValues('address')}
              {...registerField(deliveryInfoForm, 'address')}
              error={deliveryInfoForm.formState.errors.address?.message || ''}
              enterKeyHint='done'
              isLightTheme={isLightTheme}
            />
            <FormInput
              placeholder={LocalizedStrings.countryInputPlaceholder}
              value={deliveryInfoForm.getValues('country')}
              {...registerField(deliveryInfoForm, 'country')}
              error={deliveryInfoForm.formState.errors.country?.message || ''}
              enterKeyHint='done'
              isLightTheme={isLightTheme}
            />
            <FormInput
              placeholder={LocalizedStrings.cityInputPlaceholder}
              value={deliveryInfoForm.getValues('city')}
              {...registerField(deliveryInfoForm, 'city')}
              error={deliveryInfoForm.formState.errors.city?.message || ''}
              enterKeyHint='done'
              isLightTheme={isLightTheme}
            />
            <FormInput
              placeholder={LocalizedStrings.postcodeInputPlaceholder}
              value={deliveryInfoForm.getValues('postcode')}
              type="number"
              {...registerField(deliveryInfoForm, 'postcode')}
              error={deliveryInfoForm.formState.errors.postcode?.message || ''}
              enterKeyHint='done'
              isLightTheme={isLightTheme}
            />
          </Section>
        {/* </form>

        <form
          onSubmit={handleSubmit}
          style={{ marginTop: '-12dpx' }}
        > */}
        <Section header = {<h2 className={styles.SectionHeader}>{LocalizedStrings.receiverSectionHeader}</h2>}>
        <FormInput
              placeholder={LocalizedStrings.nameInputPlaceholder}
              value={deliveryInfoForm.getValues('name')}
              {...registerField(deliveryInfoForm, 'name')}
              error={deliveryInfoForm.formState.errors.name?.message || ''}
              isLightTheme={isLightTheme}
            />
          <FormInput
            value={deliveryInfoForm.getValues('phone')}
            {...deliveryInfoForm.register('phone', {
              required: 'Phone number is required',
              validate: (value) =>
                value.length === 18 || 'Phone number must be fully entered'
            })}
            type="tel"
            inputmode="tel"
            autocomplete="tel"
            onChange={(ev) => { handlePhoneChange(ev) }} // Подключаем обработчик форматирования
            placeholder={LocalizedStrings.phoneNumberInputPlaceholder}
            error={deliveryInfoForm.formState.errors.phone?.message || ''}
            enterKeyHint='done'
            isLightTheme={isLightTheme}
          />
            <FormInput
              placeholder={LocalizedStrings.emailInputPlaceholder}
              value={deliveryInfoForm.getValues('email')}
              type="email"
              {...registerField(deliveryInfoForm, 'email')}
              error={deliveryInfoForm.formState.errors.email?.message || ''}
              isLightTheme={isLightTheme}
            />
          </Section>
        </form>
      </List>
    </div >
  )
}
