import z from 'zod'
import { LocalizedErrorStrings } from '../../res/localization'

const deliveryInfoSchema = z.object({
  address: z.string()
    .min(1, { message: LocalizedErrorStrings.addressRequired })
    .min(10, { message: LocalizedErrorStrings.addressTooShort }),
  country: z.string()
    .min(1, { message: LocalizedErrorStrings.countryRequired })
    .min(3, { message: LocalizedErrorStrings.countryTooShort }),
  city: z.string()
    .min(1, { message: LocalizedErrorStrings.cityRequired })
    .min(2, { message: LocalizedErrorStrings.cityTooShort }),
  postcode: z.string()
    .min(1, { message: LocalizedErrorStrings.postcodeRequired })
    .min(5, { message: LocalizedErrorStrings.postcodeTooShort })
})

const deliveryInfoEditSchema = deliveryInfoSchema.omit({ state: true }).partial()

export { deliveryInfoSchema, deliveryInfoEditSchema }
