import { get } from 'react-hook-form'
import { LocalizedStrings, stringsLanguage } from '../res/localization'
import { getCurrencyRegularString, getPriceByCurrency, standardMeasureCalcPieceProp } from './CurrencyHelper'

export const getCorrectDeclensionOfPieces = (count) => {
  if (stringsLanguage === 'ru') {
    const lastDigit = count % 10
    const lastTwoDigits = count % 100

    if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
      return `${count} штук`
    }

    if (lastDigit === 1) {
      return `${count} штука`
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
      return `${count} штуки`
    }

    return `${count} штук`
  }
  if (stringsLanguage === 'en') {
    if (count === 1) {
      return count + LocalizedStrings.piecePrefixFull
    } else {
      return count + LocalizedStrings.pieceManyPrefixFull
    }
  }
}

export const getOrderDetailsProductInfo = (item) => {
  if (item.good.addonProps.measureCalcValue === 'weight') {
    return item.good.article &&
            (item.good.teaCategory !== null
              ? (item.good.teaCategory + LocalizedStrings.teaCategory + ' • ')
              : '') +
                item.count + LocalizedStrings.orderDetailsPcsOfGood + item.good.addonProps.weight.replace(/\D/g, '') + LocalizedStrings.weightPrefix
  } else {
    return getCorrectDeclensionOfPieces(item.count)
  }
}

export const getOrderDetailsItemPrice = (item) => {
  if (item.good.addonProps.measureCalcValue === 'weight') {
    return getCurrencyRegularString(
      (item.count * getPriceByCurrency(item.good.price, item.packageWeight.replace(/\D/g, ''))).toFixed(2)
    )
  } else {
    return getCurrencyRegularString((item.count * getPriceByCurrency(item.good.price, standardMeasureCalcPieceProp)).toFixed(2))
  }
}
