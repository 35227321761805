import {
  List,
  Section,
  Cell,
  Input,
  Button,
  FixedLayout,
  Subheadline
} from '@telegram-apps/telegram-ui'
import { orderTea } from '../../../../res/icons'
import { LocalizedStrings } from '../../../../res/localization'
import { useContext, useEffect, useState } from 'react'
import customStorage from '../../../../CustomStorage.js'
import { getCurrencyRegularString, getPriceByCurrency, calculateOrderSumByCurrency, ThemeContext } from '../../../../lib'
import { useCartStore } from '../../../../store'
import styles from './styles.module.css'
import mainStyles from '../../../main-styles.module.css'
import { useNavigate } from 'react-router-dom'
import { ViewOrderItem, HapticTouchBottomButton } from '../../index.js'
import { cartApi } from '../../../../api'

export const CatalogOrderAddingYourOrder = () => {
  const { isLightTheme } = useContext(ThemeContext)
  const order = useCartStore((state) => state.order)
  const cart = useCartStore((store) => store)
  console.log('order', order)
  const navigate = useNavigate()
  const [isEditModeEnabled, setEditModeEnabled] = useState(false)

  // eslint-disable-next-line prefer-const
  let paymentButtonDisabled = (order.length === 0 || !order)
  const [commentText, setCommentText] = useState('')

  // const [OrderList, setOrderList] = useState([]);

  // const getCart = async () => {
  //   axios.get(`${process.env['REACT_APP_BACKEND_URL']}/api/cart`, {
  //       headers: {
  //           Authorization: `JWT ${customStorage.getItem('token')}`
  //       }
  //   }).then(response => {
  //       console.log('Ответ от сервера:', response.data);
  //       setOrderList(response.data.compositionOrders);
  //   })
  //   .catch(error => {
  //       console.error('Произошла ошибка:', error);
  //   });
  // }

  const addAdditionalPrices = (compositionOrders) => { // remove that function after server response include purchaseRub/purchaseNanoTon values
    return compositionOrders.map((order) => ({
      ...order,
      good: {
        ...order.good,
        price: {
          ...order.good.price,
          purchaseRub: 1,
          purchaseNanoTon: 10000000 * order.good.id
        }
      }
    }))
  }

  useEffect(() => {
    setCommentText(cart.comment)
  }, [cart.comment])

  const BackButton = window.Telegram.WebApp.BackButton
  BackButton.show()
  BackButton.onClick(function () {
    BackButton.hide()
  })
  window.Telegram.WebApp.onEvent('backButtonClicked', function () {
    //  hideDeliveryInfoPage()
    navigate('/')
  })

  const openDeliveryDetails = async () => {
    await cart.updateComment({ comment: commentText })
    navigate('/orderdetails')
  }

  const returnToCatalog = () => {
    navigate('/')
  }

  const onDeleteOrderItem = (id) => {
    cart.removeGood({ goodId: id })
  }

  const handleSelectPackageWeight = (id, count) => {
    cart.selectCount({ goodId: id, count })
  }

  const changeEditMode = () => {
    setEditModeEnabled(!isEditModeEnabled)
  }

  return (
    <div className={isLightTheme ? styles.MainContentWrapperLight : styles.MainContentWrapperDark}>
      <List className={styles.MainContent}>
        <Section className={styles.OrdersContainer}>
          <div className={isLightTheme ? styles.OrdersHeaderContainer : styles.OrdersHeaderContainerDark}>
            <span className={styles.OrdersHeaderTitle}>{LocalizedStrings.yourOrder}</span>
            <a className={styles.OrdersHeaderAction} onClick={changeEditMode}>
              {LocalizedStrings.editOrder}
            </a>
          </div>

          { (order.length !== 0 && order)
            ? order.sort((a, b) => a.good.id - b.good.id).map((item, index) => (
              <>
                <ViewOrderItem key={index} item={item} isLightTheme={isLightTheme} onDeleteItem={onDeleteOrderItem} onSelectPackageWeight={handleSelectPackageWeight} isEditMode={isEditModeEnabled} />
                {index !== order.length - 1 && <div className={mainStyles.SectionDivider} />}
              </>
            ))
            : <div className={isLightTheme ? styles.ProductsNotFound : styles.ProductsNotFoundDark}>{LocalizedStrings.productsNotFound}</div>}
        </Section>

        <Section
          header={<h2 className={styles.CommentSectionHeader}>{LocalizedStrings.commentBarHeader}</h2>}
          footer={<div className={styles.CommentSectionFooter}>{LocalizedStrings.commentBarFooter}</div>}
        >
          <div className={isLightTheme ? styles.SearchBarInputNew : styles.SearchBarInputNewDark}>
            <input
            placeholder={ LocalizedStrings.viewOrderCommentPlaceholder }
            onChange={(e) => setCommentText(e.target.value)}
            value={commentText}
            ></input>
          </div>
        </Section>
      </List>

      <FixedLayout className={isLightTheme ? styles.PaymentButtonContainerLight : styles.PaymentButtonContainerDark}>
        <HapticTouchBottomButton
        size='l'
        stretched
        onClick={openDeliveryDetails}
        disabled={paymentButtonDisabled}
        buttonText={LocalizedStrings.payButton}
        normalStyle={paymentButtonDisabled ? styles.PaymentButtonDisabled : styles.PaymentButton}
        pressedStyle={paymentButtonDisabled ? styles.PaymentButtonDisabled : styles.PaymentButtonPressed}
        />
      </FixedLayout>
    </div>
  )
}
