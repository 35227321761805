import { Cell } from '@telegram-apps/telegram-ui'
import { getCurrencyRegularString, getPriceByCurrency, getPriceByCurrencyString, getImageUrl, standardMeasureCalcWeightProp, standardMeasureCalcPieceProp, getOrderItemFixedCostFull } from '../../../../lib'
import { orderTea } from '../../../../res/icons'
import styles from './styles.module.css'
import ImageLoader from 'react-imageloader'
import { LocalizedStrings } from '../../../../res/localization'

const OrderTile = (props) => {
  const { image, name, category, weight, price, isLightTheme } = props
  const orderItemImageSize = 26

  function preloader () {
    return <img height={orderItemImageSize} width={orderItemImageSize} src={orderTea}></img>
  }

  return (
    <div>
    <Cell
      className={isLightTheme ? styles.OrderTile : styles.OrderTileDark}
      before={
        <div>
          <ImageLoader
            src={image}
            className={styles.OrderItemCellImage}
            alt="Product picture"
            preloader={preloader}
          >
            <img src={orderTea}></img>
          </ImageLoader>
        </div>
      }
      description={
        <span className={styles.OrderItemCellDescription}>
          {category && <>
            <span>{category + LocalizedStrings.teaCategory}</span>
            <span> • </span>
          </>}
          <span>{weight}</span>
        </span>
      }
      after={<span className={styles.OrderItemCellPrice}>{`${price}`}</span>}
    >
      <span className={styles.OrderItemCellTitle}>{name}</span>
    </Cell>
    <div style={{ height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}></div>
    </div>
  )
}

const OrderItemsByCount = ({ count, item, good, isLightTheme }) => {
  const countItems = []
  for (let i = 0; i < count; i++) {
    countItems.push(<OrderTile
      key={`${good.id}${i}`}
      image={(good?.article?.image !== null ? getImageUrl(good?.personalImage ? good?.personalImage : good?.article?.image) : orderTea)}
      name={good.caption}
      category={good?.teaCategory}
      price={getOrderItemFixedCostFull(item)}
      weight={good?.addonProps?.weight}
      isLightTheme={isLightTheme}
    />)
  }

  return countItems
}
export const OrderItems = (props) => {
  return (
    <>
      {props.data.map((item) => (
        <OrderItemsByCount item={item} good={item.good} count={item.count} key={item.good.id} isLightTheme={props.isLightTheme} />
      ))}
    </>
  )
}
