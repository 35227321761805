export function stylishPhoneNumber (phone) {
  if (!phone) { return }
  // Удаляем все нецифровые символы, чтобы оставить только цифры
  const numbersOnly = phone.replace(/\D/g, '')

  // Проверяем, что длина строки соответствует ожидаемому формату
  if (numbersOnly.length !== 11 || !numbersOnly.startsWith('8')) {
    throw new Error('Неверный формат номера телефона')
  }

  // Заменяем ведущую 8 на +7
  const formattedNumber =
      '+7 ' +
      `(${numbersOnly.slice(1, 4)}) ` +
      `${numbersOnly.slice(4, 7)} ` +
      `${numbersOnly.slice(7, 9)} ` +
      `${numbersOnly.slice(9, 11)}`

  return formattedNumber
}

export const formatPhoneNumberInput = (value) => {
  // Удаляем все нецифровые символы
  let numbersOnly = value.replace(/\D/g, '')

  // Ограничиваем длину номера
  if (numbersOnly.length > 11) {
    numbersOnly = numbersOnly.slice(0, 11)
  }

  // Форматируем номер телефона
  let formattedValue = '+7 '
  if (numbersOnly.length > 1) {
    formattedValue += '(' + numbersOnly.slice(1, 4) + ''
  }
  if (numbersOnly.length > 4) {
    formattedValue += ') ' + numbersOnly.slice(4, 7)
  }
  if (numbersOnly.length > 7) {
    formattedValue += ' ' + numbersOnly.slice(7, 9)
  }
  if (numbersOnly.length > 9) {
    formattedValue += ' ' + numbersOnly.slice(9, 11)
  }

  return formattedValue
}
