import React from 'react'
import { StyledInput } from './styled'

const phoneRegexp = /^(7|8){1}?[0-9]{0,10}$/
export const Input = React.forwardRef(({ value, placeholder, type, onKeyDown, enterKeyHint, inputMode, autoComplete, ...props }, ref) => {
  const onInput = (ev) => {
    if (type === 'phone' && !phoneRegexp.test(ev.target.value)) {
      ev.target.value = ev.target.value.slice(0, -1)
      props.onChange?.(ev)
    } else if (type === 'number' && isNaN(Number(ev.target.value))) {
      ev.target.value = ev.target.value.slice(0, -1)
      props.onChange?.(ev)
    } else {
      props.onChange?.(ev)
    }
  }

  return (
    <StyledInput
      placeholder={placeholder}
      defaultValue={value}
      {...props}
      onChange={onInput}
      onKeyDown={onKeyDown}
      type={type}
      inputMode={inputMode}
      autoComplete={autoComplete}
      enterKeyHint={enterKeyHint}
      ref={ref}
    />
  )
})

Input.displayName = 'Input'
