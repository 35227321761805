import customStorage from '../CustomStorage'

export const standardMeasureCalcWeightProp = 100
export const standardMeasureCalcPieceProp = 1

export const Currencies = {
  TON: 'ton',
  USD: 'usd',
  RUB: 'rub'
}

export const nanotonToTon = (value) => {
  return value / 1000000000
}

export const tonToNanoton = (value) => {
  return value * 1000000000
}

export const getOrderItemFixedCostFull = (item) => {
  const currency = customStorage.getItem('currency')
  switch (currency) {
    case Currencies.TON:
      return (item.fixedCostTon * (item.good.addonProps.measureCalcValue === 'weight' ? item.packageWeight.replace(/\D/g, '') : 1)).toFixed(2) + ' TON'
    case Currencies.USD:
      return '$' + item.fixedCostUsdt.toFixed(2)
    case Currencies.RUB:
      return (item.fixedCostRub * (item.good.addonProps.measureCalcValue === 'weight' ? item.packageWeight.replace(/\D/g, '') : 1)).toFixed(2) + ' ₽'
  }
}

export const getOrderItemFixedCost = (item) => {
  const currency = customStorage.getItem('currency')
  switch (currency) {
    case Currencies.TON:
      return (item.fixedCostTon * (item.good.addonProps.measureCalcValue === 'weight' ? item.packageWeight.replace(/\D/g, '') : 1)).toFixed(2)
    case Currencies.USD:
      return (item.fixedCostUsdt * (item.good.addonProps.measureCalcValue === 'weight' ? item.packageWeight.replace(/\D/g, '') : 1)).toFixed(2)
    case Currencies.RUB:
      return (item.fixedCostRub * (item.good.addonProps.measureCalcValue === 'weight' ? item.packageWeight.replace(/\D/g, '') : 1)).toFixed(2)
  }
}

export const getPriceByCurrency = (price, proportion = standardMeasureCalcWeightProp) => {
  const currency = customStorage.getItem('currency')
  switch (currency) {
    case Currencies.TON:
      return (nanotonToTon(price.purchaseNanoTon) * proportion).toFixed(2)
    case Currencies.USD:
      return (parseFloat(price.purchase) * proportion).toFixed(2)
    case Currencies.RUB:
      return (parseFloat(price.purchaseRub) * proportion).toFixed(2)
  }
}

export const getPriceByConcreteCurrency = (price, proportion = standardMeasureCalcWeightProp, currency) => {
  switch (currency) {
    case Currencies.TON:
      return (nanotonToTon(price.purchaseNanoTon) * proportion).toFixed(2)
    case Currencies.USD:
      return (parseFloat(price.purchase) * proportion).toFixed(2)
    case Currencies.RUB:
      return (parseFloat(price.purchaseRub) * proportion).toFixed(2)
  }
}

export const getPriceByCurrencyString = (price, proportion = standardMeasureCalcWeightProp) => {
  const currency = customStorage.getItem('currency')
  switch (currency) {
    case Currencies.TON:
      return (nanotonToTon(price.purchaseNanoTon) * proportion).toFixed(2) + ' TON'
    case Currencies.USD:
      return '$' + (parseFloat(price.purchase) * proportion).toFixed(2)
    case Currencies.RUB:
      return (parseFloat(price.purchaseRub) * proportion).toFixed(2) + ' ₽'
  }
}

export const getCurrencyRegularString = (value) => {
  const currency = customStorage.getItem('currency')
  switch (currency) {
    case Currencies.TON:
      return (value) + ' TON'
    case Currencies.USD:
      return '$' + value
    case Currencies.RUB:
      return value + ' ₽'
  }
}
