import { Section, Cell, List } from '@telegram-apps/telegram-ui'
import { settingsDelivery, settingsSupport, settingsLink, settingsArrow } from '../../../res/icons'
import { LocalizedStrings } from '../../../res/localization'
import { useState, useContext, useEffect } from 'react'
import { SettingsPaymentMethodPopUp, SettingsTopBanner } from '../index'
import styles from './styles.module.css'
import mainStyles from '../../main-styles.module.css'
import { ThemeContext } from '../../../lib'
import { useNavigate } from 'react-router-dom'

export const Settings = () => {
  const [selectedMethod, setSelectedMethod] = useState(null)
  const [isActiveMir, setIsActiveMir] = useState(true) // состояние для управления видимостью попапа
  const [isActiveTon, setIsActiveTon] = useState(false)
  const [isActiveTelegram, setIsActiveTelegram] = useState(false)
  const { isLightTheme } = useContext(ThemeContext)
  const navigate = useNavigate()

  const paymentMethods = [
    { id: 'mir', label: LocalizedStrings.mirPaymentMethod, description: LocalizedStrings.mirPaymentMethodDescription, active: setIsActiveMir, isActive: isActiveMir },
    { id: 'ton', label: LocalizedStrings.tonPaymentMethod, description: LocalizedStrings.tonPaymentMethodDescription, active: setIsActiveTon, isActive: isActiveTon }
    // { id: 'telegram', label: 'Telegram Stars', description: 'Pay with Telegram Stars', active: setIsActiveTelegram, isActive: isActiveTelegram }
  ]
  const [currentActiveId, setCurrentActiveId] = useState(localStorage.getItem('selectedPaymentMethod') || 'mir')

  try {
    const BackButton = window.Telegram.WebApp.BackButton
    BackButton.hide()
  } catch (error) {
    console.log('something went wrong while hiding back button: ', error)
  }

  useEffect(() => {
    localStorage.setItem('selectedPaymentMethod', currentActiveId)
  }, [currentActiveId])

  return (
    <div className={isLightTheme ? styles.MainContentWrapperLight : styles.MainContentWrapperDark}>
      <List className={styles.MainContent}>

      <SettingsTopBanner />

      <Section header = {<h2 className={styles.ContentSectionHeader}>{LocalizedStrings.accountBarHeader}</h2>}>

          <SettingsPaymentMethodPopUp
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
            paymentMethods={paymentMethods}
            currentActiveId={currentActiveId}
            setCurrentActiveId={setCurrentActiveId}
          />
          <div className={mainStyles.SectionDivider}></div>
          <Cell
            className={isLightTheme ? styles.SettingCell : styles.SettingCellDark}
            before={<img src={settingsDelivery} alt="Delivery" />}
            after={<img src={settingsArrow} alt="Arrow" />}
            onClick={() => navigate('/settings/delivery-info')}
          >
            {LocalizedStrings.deliveryInformation}
          </Cell>
        </Section>
        <Section
        header={<h2 className={styles.ContentSectionHeader}>{LocalizedStrings.otherSettingsBarHeader}</h2>}
        // footer={<div style={{ fontWeight: '400', fontSize: '13px', margin: '8px 16px', color: 'rgba(112, 117, 121, 1)' }}>Any additional comment to your order can be added here</div>}
        >
          <Cell
            className={isLightTheme ? styles.SettingCell : styles.SettingCellDark}
            before={<img src={settingsSupport} alt="Support" />}
            after={<img src={settingsArrow} alt="Arrow" />}
            onClick={() => window.open('https://t.me/chkp2323', '_blank')}
          >
            {LocalizedStrings.support}
          </Cell>
          {/* <Cell
            before={<img src={settingsLink} alt="Link" />}
            after={<img src={settingsArrow} alt="Arrow" />}
            onClick={() => window.open('https://www.instagram.com/2323chkp?igsh=MWs5dTR4cWpnZzRvNQ==', '_blank')}
          >
            {LocalizedStrings.privacyPolicy}
          </Cell> */}
          <Cell
            className={isLightTheme ? styles.SettingCell : styles.SettingCellDark}
            before={<img src={settingsLink} alt="Link" />}
            after={<img src={settingsArrow} alt="Arrow" />}
            onClick={() => navigate('/terms')}
          >
            {LocalizedStrings.termsOfUsage}
          </Cell>
          <Cell
            className={isLightTheme ? styles.SettingCell : styles.SettingCellDark}
            before={<img src={settingsLink} alt="Link" />}
            after={<img src={settingsArrow} alt="Arrow" />}
            onClick={() => window.open('https://www.instagram.com/2323chkp', '_blank')}
          >
            {LocalizedStrings.ourInstagram}
          </Cell>
        </Section>
      </List>
    </div>
  )
}
