import z from 'zod'
import { LocalizedErrorStrings } from '../../res/localization'

const receiverSchema = z.object({
  name: z.string()
    .min(1, { message: LocalizedErrorStrings.nameRequired })
    .min(2, { message: LocalizedErrorStrings.nameTooShort }),
  phone: z.string()
    .min(1, { message: LocalizedErrorStrings.phoneRequired })
    .min(18, { message: LocalizedErrorStrings.phoneTooShort }),
  email: z.string()
    .min(1, { message: LocalizedErrorStrings.emailRequired })
    .email({ message: LocalizedErrorStrings.emailInvalid })
})

const receiverEditSchema = receiverSchema.partial()

export { receiverSchema, receiverEditSchema }
