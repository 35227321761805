import styled from '@emotion/styled'

export const FormInputError = styled.p`
  color: var(--tgui--destructive_text_color);
  font-size: 10px;
  position: absolute;
  left: 16px;
  bottom: 1px;
  margin: 0;
`
