import { useState, useRef, useContext, useEffect } from 'react'
import { Popper, List } from '@telegram-apps/telegram-ui'
import { settingsArrow, settingsChevronVertical, settingsPopupAction, walletCopy, walletDisconnect } from '../../../../../res/icons'
import styles from './styles.module.css'
import { ThemeContext } from '../../../../../lib'
import { LocalizedStrings } from '../../../../../res/localization'

export const PackageCountPopUp = (props) => {
  const { type, count, onePackageWeight, availableCount, onSelectPackageWeight } = props
  const targetRef = useRef(null)
  const popperRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const { isLightTheme } = useContext(ThemeContext)
  const [packageWeightsList, setPackageWeightsList] = useState([])

  const handleClickCapture = () => {
    setIsOpen((prev) => !prev)
  }

  const handleClickOutside = (event) => {
    if (
      targetRef.current &&
      !targetRef.current.contains(event.target) &&
      popperRef.current &&
      !popperRef.current.contains(event.target)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    buildPackageWeightsList()
  }, [count, onePackageWeight, availableCount])

  const buildPackageWeightsList = () => {
    const packageWeights = []
    for (let i = 0; i < availableCount; i++) {
      packageWeights.push((i + 1) * (type === 'weight' ? parseInt(onePackageWeight.replace(/\D/g, '')) : 1))
    }
    setPackageWeightsList(packageWeights)
  }

  const isSelectedItem = (item) => {
    return count * (type === 'weight' ? parseInt(onePackageWeight.replace(/\D/g, '')) : 1) === item
  }

  const handleSelectPackageWeight = (index) => {
    // if (isSelectedItem(item)) {
    //   setIsOpen(false)
    // } else {
    onSelectPackageWeight(index + 1)
    setIsOpen(false)
    // }
    // props.onSelectPackageWeight(item)
  }

  return (
    <div>
      <div onClickCapture={handleClickCapture} ref={targetRef} >
        {/* <span className={styles.paymentMethodText}>{LocalizedStrings.paymentMethod}</span> */}
        <div className={styles.methodsRight}>
        <div style={{ display: 'flex', opacity: isOpen ? 0.3 : 1, justifyContent: 'space-between', marginTop: '6px', padding: '4px 0', width: 'fit-content', backgroundColor: '#007BFF', borderRadius: '16px' }}>
            <div style={{ alignItems: 'center', alignContent: 'center', display: 'flex', margin: '0 4px' }}>
                <span style={{ color: 'white', fontSize: '17px', fontWeight: '600', margin: '0 2px 0px 4px' }}>
                  {
                  type === 'weight'
                    ? count * parseInt(onePackageWeight.replace(/\D/g, '')) + LocalizedStrings.weightPrefix
                    : count + (count === 1 ? (LocalizedStrings.piecePrefix) : (LocalizedStrings.pieceManyPrefix))
                  }
                  </span>
            {/* <TruncatedString style={{ marginRight: '8px' }} str={walletAddress} startChars={4} endChars={4} /> */}
                <img style={{ filter: 'brightness(100)' }} src={settingsChevronVertical} alt="Arrow" />
            </div>
        </div>
        </div>
      </div>
      {isOpen && (
        <Popper
          className={styles.PopperSelectWeight}
          arrowProps={{
            style: {
              display: 'none'
            }
          }}
          targetRef={targetRef}
          ref={popperRef}
          placement="bottom-end"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, -15] // Raised the pop-up by 15 pixels
              }
            }
          ]}
        >
          <div className={isLightTheme ? styles.SelectWeightWrapperLight : styles.SelectWeightWrapperDark}>
            <List className={styles.PackageWeightsList}>
                {
                    packageWeightsList.map((item, index) => (
                        <div key={index} onClick={() => handleSelectPackageWeight(index)} className={isSelectedItem(item) ? styles.PackageWeightCellSelected : styles.PackageWeightCell}>
                            <div key={index} className={styles.PackageWeightCellText}>
                              {
                              type === 'weight'
                                ? item + LocalizedStrings.weightPrefix
                                : (item === 1 ? (item + LocalizedStrings.piecePrefix) : (item + LocalizedStrings.pieceManyPrefix))
                              }
                              </div>
                            {isSelectedItem(item) ? <img src={settingsPopupAction} alt="Action" /> : null}
                        </div>
                    ))
                }
            </List>
          </div>
        </Popper>

      )}
    </div>
  )
}
