import { Currencies, getOrderItemFixedCost, getPriceByConcreteCurrency, getPriceByCurrency } from './index'

export const calculateOrderSum = (compositionOrders) => {
  return compositionOrders.reduce((sum, product) => sum + (product.count * (product.good.price.purchase * (product.packageWeight ? product.packageWeight.replace(/\D/g, '') : 1))), 0)
}

export const calculateOrderSumByCurrency = (compositionOrders) => {
  return compositionOrders.reduce((sum, item) => sum + (item.count * getOrderItemFixedCost(item)), 0)
}

export const calculateOrderSumByConcreteCurrency = (compositionOrders, currencyType) => {
  return compositionOrders.reduce((sum, item) => sum + (item.count * getPriceByConcreteCurrency(item.good.price, (item.packageWeight ? item.packageWeight.replace(/\D/g, '') : 1), currencyType)), 0)
}
