export const terms = `Условия использования сайта интернет-магазина "23:23"

Добро пожаловать на сайт интернет-магазина "23:23". Пользуясь данным сайтом, вы принимаете настоящие условия. Пожалуйста, ознакомьтесь с ними внимательно.

Общие положения
1.1. Сайт предназначен для предоставления информации о товарах и услугах, а также для оформления заказов.
1.2. Все материалы, представленные на сайте, являются собственностью "23:23" и защищены законодательством РФ.

Регистрация и оформление заказов
2.1. Для оформления заказа необходимо указать достоверные данные.
2.2. Магазин оставляет за собой право отказать в обслуживании лицам, предоставившим недостоверные данные.

Оплата и доставка
3.1. Оплата товаров осуществляется на основании выставленного счета.
3.2. Доставка выполняется в сроки, согласованные с покупателем.

Реквизиты компании
Название: АХМАТОВ ТИМУР ВЛАДИКОВИЧ (ИП)
ИНН: 183113064653
Номер счёта: 40802810902840005080 (АО "АЛЬФА-БАНК", БИК: 044525593)
Кор. счёт: 30101810200000000593
Юридический адрес: улица Кирова, д. 104, кв./оф. 87, Удмуртская Республика, г. Ижевск
Фактический адрес: Красногвардейский 1-й проезд, д. 22, стр. 2
Контакты: 2323chkp@gmail.com, +7 (963) 625-23-25

Политика возврата
5.1. Возврат товаров надлежащего качества осуществляется в течение 14 дней с момента получения заказа.
5.2. Для возврата товаров ненадлежащего качества требуется связаться с нами по указанным контактам.

Изменения в условиях
Мы оставляем за собой право изменять настоящие условия в любое время без предварительного уведомления. Актуальная версия всегда доступна на сайте.

Если у вас есть вопросы, свяжитесь с нами по контактным данным, указанным выше.

Спасибо за ваш выбор!`
