import { Tabbar, Badge, Snackbar, Spinner, Button } from '@telegram-apps/telegram-ui'
import {
  settingsCatalog,
  settingsOrders,
  settingsSettings,
  settingsCatalogClicked,
  settingsOrdersClicked,
  settingsSettingsClicked,
  teastoreQR
} from '../../res/icons'
import styles from './styles.module.css'
import { useState, useEffect, useContext } from 'react'
// import CatalogMain from "../Catalog/Main/CatalogMain";
// import OrdersMain from "../Orders/Main/OrdersMain";
// import SettingsMain from "../Settings/Main/SettingsMain";
import { LocalizedErrorStrings, LocalizedStrings } from '../../res/localization'
import { Outlet, useMatch, useLocation, useNavigate } from 'react-router-dom'

// import { retrieveLaunchParams } from '@telegram-apps/sdk';
// import axios from "axios";
import customStorage from '../../CustomStorage'
import { Currencies, ThemeContext } from '../../lib'
import { api } from '../../api'
import { useUserStore, useCartStore } from '../../store'
import { object } from 'zod'
import { CatalogTopBanner } from '../Catalog'

const Default = (_props) => {
  const tabs = [
    { id: 1, text: LocalizedStrings.catalog, iconNormal: settingsCatalog, iconClicked: settingsCatalogClicked, to: '/' },
    { id: 2, text: LocalizedStrings.orders, iconNormal: settingsOrders, iconClicked: settingsOrdersClicked, to: '/orders' },
    { id: 3, text: LocalizedStrings.settings, iconNormal: settingsSettings, iconClicked: settingsSettingsClicked, to: '/settings' }
  ]

  // const [IsClientValidated, setIsClientValidated] = useState(false);
  const location = useLocation()
  const { isLightTheme } = useContext(ThemeContext)
  // const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(null)
  const { isLoading, isOpenedFromTelegram, init: initUser, isValid } = useUserStore(store => store)
  const { cart, initCart } = useCartStore(store => store)
  // const isTabBarVisible = useCartStore(store => Object.keys(store.cart).length === 0)
  const [isTabBarVisible, setIsTabBarVisible] = useState(true)

  // function getTabContent(tab) {
  //     switch (tab) {
  //         case 1: return <CatalogMain tabBarVisibility={setTabBarVisibility} canGetProducts={IsClientValidated} />;
  //         case 2: return <OrdersMain ordersCount={setOrdersCount} />;
  //         case 3: return <SettingsMain />;
  //         default: return null;
  //     }
  // }
  const match = useMatch(location.pathname)
  const navigate = useNavigate()
  customStorage.setItem('currency', Currencies.RUB)

  useEffect(() => {
    api.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          setApiError(error.response.data.error.message)
        }
        return Promise.reject(error)
      }
    )
    setupCorrectScrollbarStyle()
  }, [])

  useEffect(() => {
    setupCorrectScrollbarStyle()
  }, [isLightTheme])

  useEffect(() => {
    if (isOpenedFromTelegram === true) {
      retrieveTgStartParams()
    }
  }, [isOpenedFromTelegram])

  useEffect(() => {
    initUser((err) => {
      // setApiError(err)
      setApiError(err?.message ?? 'init user failed')
    })
  }, [initUser])

  useEffect(() => {
    if (isValid) {
      console.log('is valid')
      initCart((err) => {
        setApiError(err?.message)
      })
    }
  }, [isValid])

  useEffect(() => {
    const tabBarHiddenScreens = [
      '/vieworder',
      '/orderplaced',
      '/orderdetails',
      '/purchase/success',
      '/purchase/fail',
      '/terms'
    ]

    if (tabBarHiddenScreens.includes(location.pathname)) {
      setIsTabBarVisible(false)
      if (location.pathname === '/orderplaced') {
        initCart((err) => {
          setApiError(err?.message)
        })
      }
    } else if (location.pathname === '/' && Object.keys(cart).length !== 0) {
      setIsTabBarVisible(false)
    } else {
      setIsTabBarVisible(true)
    }
  }, [location, Object.keys(cart).length])

  const setupCorrectScrollbarStyle = () => {
    if (isLightTheme === true) {
      document.documentElement.style.setProperty('--scrollbar-thumb-color', 'rgba(90, 90, 90, 0.3)')
      document.documentElement.style.setProperty('--scrollbar-track-color', '#EFEFF4')
      document.documentElement.style.setProperty('--skeleton-color', '#FFFFFF')
    } else {
      document.documentElement.style.setProperty('--scrollbar-thumb-color', 'rgba(90, 90, 90, 0.3)')
      document.documentElement.style.setProperty('--scrollbar-track-color', '#0F0F0F')
      document.documentElement.style.setProperty('--skeleton-color', '#0F0F0F')
    }
  }

  const selectCorrectFullscreenFormat = () => {
    try {
      const platform = window.Telegram.WebApp.platform
      let stylePlatform = ''
      if (platform === 'unknown') {
        return
      }
      if (platform === 'ios') {
        stylePlatform = styles.fullscreenIos
      }
      if (platform === 'android') {
        stylePlatform = styles.fullscreenAndroid
      }
      if (platform === 'tdesktop' || platform === 'weba') {
        stylePlatform = styles.fullscreenDesktop
      }
      return stylePlatform
    } catch (error) {
      console.log('something went wrong while select correct fullscreen format: ', error)
    }
  }

  const retrieveTgStartParams = () => {
    try {
      const params = new URLSearchParams(window.Telegram.WebApp.initData)
      const encodedParams = params.get('start_param')
      const decodedParams = atob(encodedParams) // Декодируем из base64
      const parsedParams = new URLSearchParams(decodedParams)

      if (parsedParams.get('path') === 'orderplaced' && parsedParams.get('orderId')) {
        navigate('/orderplaced', { state: { orderId: parsedParams.get('orderId'), purchaseType: 'robokassa' } })
      }
    } catch (error) {
      console.log('something went wrong while retrieve TG mini-app start params: ', error)
    }
  }

  if (isLoading || isOpenedFromTelegram === null) {
    return (
      <div
        className={`${styles.fullscreenContainer} ${
          isLightTheme
            ? styles.fullscreenContainerLight
            : styles.fullscreenContainerDark
        }`}
      >
        <Spinner size="l" />
      </div>
    )
  } else if (isOpenedFromTelegram === false && location.pathname !== '/purchase/success') {
    return (
      <div
        className={`${styles.fullscreenContainer} ${styles.errorContainer} ${
          isLightTheme ? styles.errorContainerLight : styles.errorContainerDark
        }`}
      >
        <div className={styles.mainWrapper}>
        <CatalogTopBanner customSubtitle={LocalizedStrings.appAvailableOnlyInTelegram} />
        <img className={styles.qrImage} src={teastoreQR}></img>
        <Button
          className={styles.openInTelegramButton}
          onClick={() => window.open('https://t.me/TeaStore2323_bot', '_blank')}
          size="l"
        >
            {LocalizedStrings.openInTelegram}
          </Button>
        </div>
      </div>
    )
  } else {
    if (isOpenedFromTelegram === true || location.pathname === '/purchase/success') {
      return (
        <div className={`${isLightTheme ? styles.mainContentLight : styles.mainContentDark} ${selectCorrectFullscreenFormat()}`}>

          {/* {getTabContent(currentTab)} */}
          {apiError && (
            <Snackbar
              description={apiError}
              className={styles.snackBar}
              duration={3000}
              onClose={() => setApiError(null)}
            >
              {LocalizedErrorStrings.apiErrorTitle}
            </Snackbar>
          )}
          <Outlet />
          <Tabbar
            className={`${styles.tabBar} ${
              isLightTheme ? styles.tabBarLight : styles.tabBarDark
            } ${isTabBarVisible ? styles.tabBarVisible : styles.tabBarHidden}`}
          >
            {tabs.map(({ id, text, iconClicked, iconNormal, to }) => {
              const isSelected = match?.pathnameBase === to
              return (
                <Tabbar.Item
                  key={id}
                  text={text}
                  selected={isSelected}
                  onClick={() => navigate(to)}
                  // onClick={() => setCurrentTab(id)}
                  className={`${styles.tabBarItem} ${
                    isSelected
                      ? styles.tabBarItemSelected
                      : styles.tabBarItemUnselected
                  }`}
                >
                  <div>
                    {/* <Badge  //НЕ УДАЛЯТЬ, ВОЗМОЖНО В БУДУЩЕМ ПОНАДОБИТСЯ БЭЙДЖ КОЛИЧЕСТВА ОРДЕРОВ
                                    style={{
                                    position: "absolute",
                                    top: 4,
                                    marginLeft: "24px",
                                    visibility: (OrdersCount > 0 && id === 2 && TabBarVisibility === true) ? "visible" : "hidden",
                                    backgroundColor: "#007AFF",
                                    }}
                                    mode="primary"
                                    type="number"
                                >
                                    {OrdersCount}
                                </Badge> */}
                    <img
                      className={styles.tabBarIcon}
                      src={isSelected ? iconClicked : iconNormal}
                      alt="tabbar-image"
                    />
                  </div>
                </Tabbar.Item>
              )
            })}
          </Tabbar>
        </div>
      )
    }
  }
}

export default Default
