import { LocalizedEnglishStrings as eng, LocalizedErrorEnglishStrings as engError } from './eng'
import { LocalizedRussianStrings as ru, LocalizedErrorRussianStrings as ruError } from './ru'
import customStorage from '../../CustomStorage'

// export const LocalizedStrings = customStorage.getItem('language') === 'ru' ? ru : eng
// export const LocalizedErrorStrings = customStorage.getItem('language') === 'ru' ? ruError : engError

export const stringsLanguage = 'ru'

export const LocalizedStrings = stringsLanguage === 'ru' ? ru : eng
export const LocalizedErrorStrings = stringsLanguage === 'ru' ? ruError : engError

// export const LocalizedStrings = eng
// export const LocalizedErrorStrings = engError
