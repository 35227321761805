import { api } from '.'

export function generateRobokassaLink (payload) {
  return api.post('/api/transactions/robokassa/generate-transaction-link', payload)
}

export function getRobokassaPendingTransactions () {
  return api.get('/api/transactions/robokassa/pending-transactions')
}

export function sendRobokassaPurchaseSuccess (payload) {
  return api.post('/api/transactions/robokassa/purchase-success', payload)
}
