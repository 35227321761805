import { Cell, Subheadline } from '@telegram-apps/telegram-ui'
import { orderTea, orderDeleteGood } from '../../../../../res/icons'
import styles from './styles.module.css'
import { getCurrencyRegularString, getPriceByCurrency, getImageUrl, standardMeasureCalcPieceProp } from '../../../../../lib'
import ImageLoader from 'react-imageloader'
import { LocalizedStrings } from '../../../../../res/localization'
import { PackageCountPopUp } from './PackageCountPopUp'
import { getCorrectDeclensionOfPieces } from '../../../../../lib/WordChangeHelper'

export const ViewOrderItem = (props) => {
  const { item, isLightTheme, onDeleteItem, onSelectPackageWeight, isEditMode } = props
  console.log('ViewOrderItem', item)

  function preloader () {
    return <img height={55} width={55} src={orderTea}></img>
  }

  const handleDeleteItem = () => {
    onDeleteItem(item.good.id)
  }

  const handleSelectPackageWeight = (count) => {
    onSelectPackageWeight(item.good.id, count)
  }

  return (
<div className={isLightTheme ? styles.container : styles.containerDark}>
    <div className={(isEditMode === true) ? styles.gridContainerEdit : styles.gridContainer}>
        <div className={styles.flexContainer}>
            <ImageLoader
                src={getImageUrl(item.good.personalImage ? item.good.personalImage : item.good.article.image)}
                className={styles.productImage}
                alt="Product picture"
                preloader={preloader}
            >
                <img src={orderTea}></img>
            </ImageLoader>

            <div className={styles.textContainer}>
                <div className={styles.caption}>{item.good.caption}</div>
                <div className={styles.description}>
                    {item.good && item.good.description}
                </div>
                <div className={styles.additionalInfo}>
                    {
                    (item.good.article && (item.good.teaCategory !== null ? (item.good.teaCategory + LocalizedStrings.teaCategory) : '')) +
                    ((item.good.teaCategory !== null && (item.good.addonProps.weight !== null && !isEditMode)) ? (' • ') : ('')) +
                    ((!isEditMode)
                      ? (item.good.addonProps.measureCalcValue === 'weight'
                          ? (item.good.addonProps.weight) && (item.count * parseInt(item.good.addonProps.weight.replace(/\D/g, '')) + LocalizedStrings.weightPrefixFull)
                          : getCorrectDeclensionOfPieces(item.count)
                        )
                      : ''
                    )
                    }
                </div>
                {
                  isEditMode &&
                  <PackageCountPopUp
                  type={item.good.addonProps.measureCalcValue}
                  count={item.count}
                  onePackageWeight={item.good.addonProps.weight}
                  availableCount={item.good.warehouse.availability}
                  onSelectPackageWeight={handleSelectPackageWeight}
                  ></PackageCountPopUp>
                }
            </div>
        </div>
        <div className={isEditMode ? styles.priceEdit : styles.price}>
            {getCurrencyRegularString(
              (item.count * (
                getPriceByCurrency(
                  item.good.price, item.good.addonProps.measureCalcValue === 'weight' ? item.packageWeight.replace(/\D/g, '') : standardMeasureCalcPieceProp
                )
              )).toFixed(2)
            )}
        </div>
        {
          isEditMode &&
          <><div style={{ height: '118%', width: '0.5px', backgroundColor: 'rgba(0, 0, 0, 0.07)', marginLeft: '9vw' }}></div><div>
            <img src={orderDeleteGood} onClick={handleDeleteItem}></img>
          </div></>
        }

    </div>
</div>
  )
}
